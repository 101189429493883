.website-container {
  /* Global styles */
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #4a4a4a;
  background-color: #f7f8fa;
  scroll-behavior: smooth;
  overflow-x: clip;
  -webkit-tap-highlight-color: transparent;

  @media screen and (max-width: 880px) {
    scroll-margin: 73px;
  }

  .container {
    width: 100%;
    max-width: 1472px;
    margin: 0 auto;
    padding: 0 16px;
  }

  .header {
    background-color: #4050fb;
    padding: 20px 0;
    width: 100%;

    @media screen and (max-width: 880px) {
      padding: 10px 0;
      position: fixed;
      box-shadow: 0 1px 4px #00000044;
      z-index: 1;
    }
  }

  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header .logo {
    line-height: 1;
  }

  .header .logo img {
    height: 60px;

    @media screen and (max-width: 880px) {
      height: 40px;
    }
  }

  .mobile-menu-toggle {
    line-height: 1;
    display: none;

    @media screen and (max-width: 880px) {
      display: block;
    }
  }

  .header .nav {
    display: flex;
    gap: 37px;
    align-items: center;

    &:target,
    &.open {
      display: flex;
      right: 0;
    }

    @media screen and (max-width: 880px) {
      display: none;
      gap: 24px;
      align-items: flex-end;
      flex-direction: column;
      position: absolute;
      max-width: 300px;
      background: #4050fbee;
      width: 100%;
      right: -300px;
      padding: 20px;
      box-shadow: 0 0 4px #00000044;
      top: 63px;
      height: calc(100dvh - 63px);
      overflow-y: auto;
      transition: all ease 0.3s;
      transition-behavior: allow-discrete;

      @starting-style {
        display: none;
        right: -300px;
      }
    }
  }

  .header .nav a:not(.button),
  footer a {
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    transition: all 0.5s ease;
  }

  .header .nav a:not(.button):hover,
  footer a:hover {
    box-shadow: 0px 2px 0;
  }

  /* Hero section */
  .hero {
    background-color: #4050fb;
    padding: 93px 0 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      padding: 64px 0 32px;
    }
  }

  .hero .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hero .hero-text {
    max-width: 600px;
    margin-bottom: 30px;
  }

  .hero .hero-text h1 {
    font-size: 48px;
    color: #fff;
    font-weight: normal;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      font-size: 36px;
    }
  }

  .hero .buttons {
    margin-top: 20px;
    display: flex;
    gap: 16px;
  }

  .button {
    padding: 11px 22px;
    color: #fff;
    border: 1px solid #fff;
    text-decoration: none;
    border-radius: 36px;
    font-size: 16px;
    font-weight: 400;
  }

  .button.contained {
    background-color: #fff;
    color: #4050fb;
  }

  .hero .hero-image {
    margin-right: 0;
    margin-left: auto;
    max-height: 629px;
    overflow: hidden;
    object-fit: contain;
    border-radius: 16px 16px 0 0;
    max-width: 722px;
    line-height: 0;
    margin-bottom: 0;
    margin-top: auto;
    position: relative;
    right: -16px;

    @media screen and (max-width: 1470px) {
      border-radius: 16px 0 0 0;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .hero .hero-image img {
    max-width: 100%;
  }

  /* Trust section */
  .trust {
    background-color: #f0f1ff;
    padding: 44px 0;
    text-align: center;
    overflow: hidden; /* Hide the scrollbar */
    position: relative;
  }

  .trust .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    @media screen and (max-width: 768px) {
      animation: scroll 15s linear infinite;
    }
  }

  /* Governance section */
  .governance {
    margin: 128px 64px;
    text-align: left;

    @media screen and (max-width: 767px) {
      margin: 64px 0;
    }
  }

  h2 {
    font-size: 48px;
    color: #000;
    font-weight: 400;
    max-width: 558px;
    margin: 0;
  }

  h2 span {
    color: #4050fb;
  }

  .governance h6,
  .solutions h6,
  .audience h6 {
    font-size: 20px;
    color: #b4bafd;
    font-weight: 400;
    margin: 0;
  }

  .governance p {
    max-width: 834px;
    margin: 64px 0 0 64px;
    color: #555555;

    @media screen and (max-width: 767px) {
      margin: 24px 0 0;
    }
  }

  /* Capabilities section */
  .capabilities {
    padding: 64px 64px 128px;

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .capabilities .features {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 32px;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .capabilities .feature {
    @media screen and (max-width: 767px) {
      margin-bottom: 64px;
    }
  }

  .capabilities .feature.full-width {
    grid-column-end: span 2;
  }

  .capabilities .feature h5 {
    font-size: 24px;
    color: #000;
    margin: 0 0 40px;
    font-weight: 400;

    @media screen and (max-width: 767px) {
      margin-bottom: 32px;
    }
  }

  .capabilities .feature p {
    color: #4d4d4d;
    font-size: 16px;
  }

  .capabilities .feature img {
    max-width: 100%;
    margin-bottom: 16px;
  }

  /* Solutions section */
  .solutions {
    background-color: #f0f1ff;
    padding: 128px 64px 196px;

    @media screen and (max-width: 767px) {
      padding: 128px 0 64px;
    }
  }

  .solutions .solutions-grid {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 48px;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .solutions .solution {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 64px;

    @media screen and (max-width: 767px) {
      padding: 0 0 64px;
      gap: 24px;
    }
  }

  .solutions .solution h4 {
    font-size: 32px;
    font-weight: 400;
    color: #000;
    margin: 0;
  }

  .solutions .solution p {
    font-size: 20px;
    color: #000;

    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  .solutions .solution img {
    max-width: 100%;
    margin-bottom: 10px;
    object-fit: contain;

    @media screen and (max-width: 767px) {
      height: 245px;
    }
  }

  /* Audience section styles */
  .audience {
    padding: 60px 0;
    background-color: #ffffff;
  }

  .audience h2 {
    font-size: 36px;
    color: #333333;
    margin-bottom: 40px;
  }

  .audience .vertical-tabs h3 {
    font-size: 28px;
    color: #000;
    margin: 0;
    font-weight: 400;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  /* tabs */

  .vertical-tabs {
    list-style-type: none;
    padding: 0;
  }

  .vertical-tabs li {
    margin-bottom: 24px;
    border: 1px solid #ccd1ff;
    border-radius: 16px;
    padding: 32px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
  }

  .vertical-tabs a {
    display: flex;
    gap: 16px;
    text-decoration: none;
    color: #000;

    align-items: center;
  }

  .vertical-tabs a img {
    margin-left: auto;
    margin-right: 0;

    @media screen and (max-width: 767px) {
      width: 32px;
      height: 32px;
    }
  }
  .vertical-tabs li:hover,
  .vertical-tabs li.active,
  .vertical-tabs li:active {
    background: #ffffff;
    border: 1px solid #4050fb;
    box-shadow: 0px 12px 84px rgba(196, 196, 196, 0.5);
  }

  .tab-content-item {
    transition: max-height 0.2s ease-out;
    max-height: 0;
    scroll-margin: 20px;
    font-size: 16px;
    overflow: hidden;
  }

  .vertical-tabs li:target .tab-content-item,
  .vertical-tabs li.active .tab-content-item {
    max-height: 100%;
  }

  /* Networks section */
  .networks {
    background-color: #fff;
    padding: 64px 64px 128px;
    text-align: center;

    @media screen and (max-width: 767px) {
      padding: 32px 24px;
    }
  }

  .networks h2 {
    text-align: center;
    max-width: 100%;
  }

  .networks .network-icons {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
    margin-top: 48px;
    padding: 0;
  }

  .networks .network-icons li {
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: #f5f5f5;
    padding: 16px 42px 16px 32px;
    border-radius: 100px;
    align-items: center;
  }
  .networks .network-icons li img {
    max-width: 52px;
  }
  .networks .network-icons li h5 {
    color: #000;
    font-weight: 400;
    margin: 0;
  }

  /* Download app */

  .download-app {
    background-color: #fff;

    .banner {
      border-radius: 32px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 64px;
      gap: 64px;

      background: #4050fb;
      border-radius: 32px;

      @media screen and (max-width: 767px) {
        padding: 24px;
        gap: 16px;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: max(50%, 611px);

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 32px;
          line-height: 48px;
          margin: 0;

          color: #ffffff;

          @media screen and (max-width: 767px) {
            font-size: 28px;
          }
        }

        p {
          margin: 0;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;

          color: #ffffff;

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 32px;
          white-space: pre;

          @media screen and (max-width: 880px) {
            flex-wrap: wrap;
          }

          img {
            width: 24px;
            height: 24px;
            margin: 0;
          }

          a {
            color: #fff;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 24px 32px;
            gap: 16px;
            background: #000000;
            border-radius: 999px;
          }
        }
      }

      & > img {
        overflow: hidden;
        margin-top: auto;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: 0;
        bottom: -64px;
        position: relative;

        @media screen and (max-width: 880px) {
          display: none;
        }
      }
    }
  }

  /* Team section */
  .team {
    padding: 60px 0;
    text-align: center;
    background-color: #fff;

    h2 {
      margin: auto;
      margin-bottom: 64px;
    }

    .team-members {
      display: flex;
      flex-wrap: wrap;
      padding: 0px;
      gap: 96px 64px;
      justify-content: center;

      .team-member {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 17px;
        min-width: 240px;

        h5 {
          margin: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          text-align: center;
          text-transform: uppercase;

          color: #000000;
        }

        h6 {
          margin: 0;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 150%;
          /* identical to box height, or 24px */
          text-align: center;

          color: #000000;
        }

        p {
          max-width: 215px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          /* or 24px */
          text-align: center;

          color: #000000;
        }

        a.disabled {
          img {
            opacity: 0.3;
          }
        }
      }
    }
  }

  /* Platforms */
  .platforms {
    height: 78px;
    overflow: hidden; /* Hide the scrollbar */
    position: relative;

    .container {
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      padding: 24px 64px;
      gap: 32px;
      @media screen and (max-width: 768px) {
        animation: scroll 15s linear infinite;
      }
    }
  }

  /* Contact section */
  .contact {
    background: url('/public/images/website/contact-bg.svg') left bottom no-repeat #fff;
    padding: 64px 64px 128px;
    text-align: center;

    @media screen and (max-width: 767px) {
      padding: 32px 0;
    }
  }

  .contact h2 {
    margin: auto;
  }

  .contact p {
    margin: 0;
    margin-bottom: 64px;
  }

  .contact form {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 24px;

    max-width: 620px;
    margin: 0 auto;

    align-items: center;
    padding: 0px;

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }
  .contact form .error-message {
    color: red;
    font-size: 12px;
    margin: 0;
    text-align: right;
    grid-column-end: span 2;
  }

  .contact form input,
  .contact form textarea {
    padding: 15px;
    margin-bottom: 10px;
    border: none;
    border-radius: 7px;
    background-color: #f5f6ff;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .contact form textarea {
    grid-column-end: span 2;
  }

  .contact form .submit-button {
    grid-column-end: span 2;
    text-align: right;
  }

  .contact form .button {
    cursor: pointer;
    color: #fff;
    padding: 24px 64px;
    background: #4050fb;
    border-radius: 999px;
  }

  /* Footer */
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 64px 0;
    background: #4050fb;
    font-size: 14px;

    a {
      color: #fff;
      text-decoration: none;
    }

    .left-column {
      color: #fff;
      max-width: 311px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      margin: 0 auto 0 0;

      img {
        margin-bottom: 20px;
      }

      p {
        margin: 0;
      }
    }

    .right-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding: 0px;
      gap: 27px;

      margin: 0 auto;
      max-width: 262px;
    }
  }

  .footer p {
    margin-bottom: 10px;
  }

  .footer .social-links a {
    margin: 0 10px;
  }

  .footer .social-links img {
    max-width: 20px;
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 36px;
    }
  }
}
